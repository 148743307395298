import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';

const GoogleSignIn = ({ setUser }) => {
    const googleLogin = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            const userInfo = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
                headers: {
                    Authorization: `Bearer ${tokenResponse.access_token}`,
                },
            });

            console.log(userInfo.data); // Handle the authenticated user info
            setUser({ success: true, data: userInfo.data })
        },
        onError: (error) => {
            console.error('Login Failed:', error);
            setUser({ success: false })
        },
    });

    return (
        <div>
            <button type="button"
                className="btn inter-400"
                id="google-sign-in"
                style={{ fontSize: '16px', lineHeight: '26px', marginBottom: '15px' }}
                onClick={() => googleLogin()}>
                <FontAwesomeIcon icon={faGoogle} />&nbsp; Continue with Google
            </button>
        </div>
    );
};

export default GoogleSignIn;
