import api from './api';
import moment from 'moment-timezone';


const createEvent = async (formData) => {
  try {
    const response = await api.post('/event', formData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getEventDetails = async (eventId) => {
  try {
    const response = await api.get(`/event/${eventId}`);
    console.log('response', response)
    return response.data;
  } catch (error) {
    throw error;
  }
};

const updateEvent = async (eventId, formData) => {
  try {
    const response = await api.put(`/event/${eventId}`, formData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const formatEventTime = (startTimestamp, endTimestamp) => {

  // Convert UTC timestamps to Eastern Time
  const startTime = moment.utc(startTimestamp);
  const endTime = moment.utc(endTimestamp);

  // Format the start time and end time
  const formattedStartTime = startTime.format('ddd. MMM. D, hh:mm A');
  const formattedEndTime = endTime.format('hh:mm A');

  // Combine both times
  return `${formattedStartTime} - ${formattedEndTime}`;
}

const getEventDetailsBySlug = async (slug) => {
  try {
    const response = await api.get(`/event/slug/${slug}`);
    console.log('response', response)
    return response.data;
  } catch (error) {
    throw error;
  }
}

const getEventsForUser = async (userId) => {
  try {
    const response = await api.get(`/event/user/${userId}`)
    return { success: true, data: response.data }
  } catch (error) {
    if (error.response?.status === 404) {
      return { success: false, data: null, errorMessage: 'No events found'}
    } else {
      return { success: false, data: null, errorMessage: 'There was an issue getting the events'}
    }
    
  }
}

const getUpcomingEvents = async () => {
  try {
    const response = await api.get(`/event/upcoming`)
    return { success: true, data: response.data }
  } catch (error) {
    if (error.response?.status === 404) {
      return { success: false, data: null, errorMessage: 'No events found'}
    } else {
      return { success: false, data: null, errorMessage: 'There was an issue getting the events'}
    }

  }
}

const getUpcomingNonPromotedEvents = async () => {
  try {
    const response = await api.get(`/event/non-promoted-upcoming`)
    return { success: true, data: response.data }
  } catch (error) {
    if (error.response?.status === 404) {
      return { success: false, data: null, errorMessage: 'No events found'}
    } else {
      return { success: false, data: null, errorMessage: 'There was an issue getting the events'}
    }

  }
}

const uploadImage = async (data) => {
  try {
    const response = await api.post(`/event/s3/upload`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })

    return { success: true, data: response.data }
  } catch (error) {
    return { success: false, data: null }
  }
}

const deleteEvent = async (data) => {
  try {
    const response = await api.delete(`/event/${data.eventId}/${data.promoterId}`)
    console.info(response);
    return ({ success: true })
  } catch (error) {
    return ({ success: false })
  }
}

const rsvpForEvent = async () => {
  const invite = JSON.parse(localStorage.getItem('invite'))
  const user = JSON.parse(localStorage.getItem('user'))
  try {
    const data = {
      slug: invite.event,
      userId: user.userId
    }

    if (invite.influencerId) {
      data.influencerId = invite.influencerId;
    }

    const response = await api.post('/event/rsvp', data)
    return ({ success: true, data: response.data })
  } catch (error) {
    console.error(error)
    return ({ success: false })
  }
}

const shareEventByEmail = async (email, eventSlug) => {
  // Validate input
  if (!email || !eventSlug) {
    throw new Error('Email and event slug are required');
  }

  try {
    // Send request to share event via email
    const response = await api.post('/event/share-event-email', { email, eventSlug });

    // Log success message
    console.log('Event shared via email successfully');

    return response.data;
  } catch (error) {
    // Log error
    console.error('Error sharing event via email:', error);

    // Throw specific error message
    throw new Error('Failed to share event via email');
  }
};


const setReminder = async (formData) => {
  try {
    const response = await api.post('/event/set-reminder', formData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export {
  createEvent,
  getEventDetails,
  updateEvent,
  getEventsForUser,
  getUpcomingEvents,
  getUpcomingNonPromotedEvents,
  uploadImage,
  deleteEvent,
  getEventDetailsBySlug,
  rsvpForEvent,
  formatEventTime,
  shareEventByEmail,
  setReminder,
};
