import React from 'react';
import { Outlet } from 'react-router-dom';
import ReactGA from 'react-ga'
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { UserProvider } from './hooks/UserContext';
import Navigation from './components/common/navigation/Navigation';
import './App.css';

const TRACKING_ID = "G-4FS6LBKBVY"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(TRACKING_ID);


function App() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);


  return (
    <div className="App">
      <UserProvider>
        <Outlet/>
        <Navigation/>
      </UserProvider>
    </div>
  );
}

export default App;
