import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
//
import { createGuestUser } from '../../../services/userService';
import { rsvpForEvent } from '../../../services/eventService';
import Toasts from '../../common/toasts/Toasts';

function GuestModal({ showModal, setShowModal }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState('');
  const [formData, setGuestFormData] = useState({
    guestEmail: '',
  });
  const [errors, setErrors] = useState({
    guestEmail: '',
  });

  const handleCloseModal = () => {
    setGuestFormData({
      guestEmail: '',
    });
    setShowModal(false);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const { guestEmail, ...userData } = formData;
      userData.role = 'guest';
      userData.email = guestEmail;

      const result = await createGuestUser(userData);

      if (result.success) {
        Cookies.set('gn_userToken', result.data.token);
        localStorage.setItem('user', JSON.stringify(result.data));

        const ticket = await rsvpForEvent();

        if (ticket) {
          const redirectPath = `/event-goer/event/tickets/${ticket.data.rsvpId}`;
          setLoading(false);
          navigate(redirectPath, { state: { user: result.data, ticket: ticket.data.ticket } });

          // Clear the form after successful submission
          setGuestFormData({
            guestEmail: '',
          });
        }

        setMessage('Registration successful.');
        setShowToast(true);
      } else {
        setLoading(false);
        console.log(result)
        setMessage(`Registration failed. ${result.message}`);
        setShowToast(true);
      }
    } catch (error) {
      console.error('Error registering user:', error);
      setMessage(`Error registering user: ${error}`);
      setShowToast(true);
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGuestFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleInputBlur = (e) => {
    const { name, value } = e.target;
    setErrors(prevState => ({
      ...prevState,
      [name]: value === '' ? `${name.charAt(0).toUpperCase() + name.slice(1)} is required` : ''
    }));
  };

  return (
    <div
      className={`modal fade ${showModal ? 'show' : ''}`}
      style={{ display: showModal ? 'block' : 'none' }}
      id="guestModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="guestModalLabel"
      aria-hidden={!showModal}
      data-bs-backdrop="static"
    >

      <Toasts show={showToast} message={message} onClose={() => setShowToast(false)} />
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="guestModalLabel">
              Continue as Guest
            </h5>
            <button
              type="button"
              className="close border-0 bg-transparent btn-outline-primary"
              onClick={handleCloseModal}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {loading && (
              <div className="d-flex justify-content-center align-items-center mb-3">
                <div className="spinner-grow" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
            <div className="mb-3">
              <label htmlFor="guestEmail" className="form-label">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                id="guestEmail"
                name="guestEmail"
                value={formData.guestEmail}
                onChange={handleChange}
                onBlur={handleInputBlur}
                placeholder="Email"
              />
              <div className="form-text text-danger">{errors?.guestEmail}</div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn mb-2" onClick={handleSubmit} disabled={!formData.guestEmail || loading}>
              Continue
            </button>
          </div>
        </div>
      </div>

      {showModal && <div className="modal-backdrop fade show z-n1"></div>}
    </div>
  );
}

export default GuestModal;
