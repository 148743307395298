import React from 'react';
import config from '../../../config/config';

const Map = ({address}) => {
  const MAP_API_KEY = config.GOOGLE_MAPS_API_KEY;

  // Construct the URL for embedding Google Maps
  const mapUrl = `https://www.google.com/maps/embed/v1/place?q=${encodeURIComponent(
    address
  )}&key=${MAP_API_KEY}`;

  return (
    <div className="map-container">
      <iframe
        title="Map"
        className="map"
        width="100%"
        height="400"
        frameBorder="0"
        style={{border: 0}}
        src={mapUrl}
        allowFullScreen=""
        loading="lazy"
      ></iframe>
    </div>
  );
};

export default Map;
