import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Profile from '../components/profile/Profile';
import { changePassword } from '../services/userService';

function ProfileContainer() {
  const location = useLocation()
  const [loading, setLoading] = useState(true);
  const user = location.state?.user || JSON.parse(localStorage.getItem('user'));
  const [formData, setFormData] = useState({
    email: '',
    currentPassword: '',
    password: '',
    confirmPassword: ''
  });
  const [errors, setErrors] = useState({
    email: '',
    currentPassword: '',
    password: '',
    confirmPassword: ''
  });
  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (user) formData.email = user.email;
    // Simulating loading delay with setTimeout
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, [formData, user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleInputBlur = (e) => {
    const { name, value } = e.target;
    let newErrors = { ...errors };

    switch (name) {
      case 'email':
        newErrors.email = value === '' ? 'Email is required' : '';
        break;
      case 'currentPassword':
        newErrors.currentPassword = value === '' ? 'Current password is required' : '';
        break;
      case 'password':
        newErrors.password = value === '' ? 'New Password is required' : '';
        break;
      case 'confirmPassword':
        newErrors.confirmPassword = value === '' ? 'Confirm password is required' : '';
        break;
      default:
        break;
    }

    // Additional validation for confirm password
    if (name === 'confirmPassword' && value !== formData.password) {
      newErrors.confirmPassword = 'Passwords do not match';
    }

    setErrors(newErrors);
  };

  const handleSubmit = async (formData) => {
    const { email, currentPassword, password } = formData;
    setLoading(true);

    try {
      const result = await changePassword(email, currentPassword, password);
      if (result) setMessage(result.message);
      setShowToast(true);
      setLoading(false);

      if (result.success) {
        setFormData({
          currentPassword: '',
          password: '',
          confirmPassword: ''
        });
      }
    } catch (error) {
      setMessage(`Error signing in user: ${error}`);
      setShowToast(true);
      setLoading(false);
    }
  };

  return (
    <Profile loading={loading}
             onSubmit={handleSubmit}
             user={user}
             formData={formData}
             handleChange={handleChange}
             handleInputBlur={handleInputBlur}
             errors={errors}
             setShowToast={setShowToast}
             showToast={showToast}
             toastMessage={message}/>
  );
}

export default ProfileContainer;
