import React, { useRef, useEffect } from 'react';
import config from '../../../config/config';

function AddressAutocomplete({ id, onPlaceSelected }) {
  const inputRef = useRef(null);
  const initializeAutocompleteRef = useRef(null); // Ref for initializeAutocomplete function
  const MAP_API_KEY = config.GOOGLE_MAPS_API_KEY;

  useEffect(() => {
    // Define initializeAutocomplete function
    window.initializeAutocomplete = () => {
      initializeAutocompleteRef.current();
    };

    initializeAutocompleteRef.current = () => {
      const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current);
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        onPlaceSelected(place);
        inputRef.current.value = place.name;
      });
    };

    const loadGoogleMaps = () => {
      if (!window.google || !window.google.maps || !window.google.maps.places) {
        // If Google Maps API or places library is not yet loaded, load the script
        return new Promise((resolve, reject) => {
          const script = document.createElement('script');
          script.src = `https://maps.googleapis.com/maps/api/js?key=${MAP_API_KEY}&libraries=places&loading=async&callback=initializeAutocomplete`;
          script.async = true;
          script.defer = true; // Load script asynchronously
          script.onerror = () => reject(new Error('Error loading Google Maps API script'));
          document.head.appendChild(script);
          script.onload = resolve;
        });
      } else {
        // If already loaded, directly initialize Autocomplete
        initializeAutocompleteRef.current();
        return Promise.resolve();
      }
    };

    // Load Google Maps API script
    loadGoogleMaps().then(() => {
      // console.log('Google Maps API loaded successfully');
    }).catch(error => {
      console.error(error);
    });

    return () => {
      // Clean up Google Maps script when component unmounts
      const googleScript = document.querySelector('script[src*="maps.googleapis.com"]');
      if (googleScript) {
        googleScript.remove();
      }
    };
  }, [onPlaceSelected, MAP_API_KEY]);

  return <input ref={inputRef} id={id} type="text" className="form-control" placeholder="Venue Name"/>;
}

export default AddressAutocomplete;
