import api from './api';

const getTickets = async (status, userId) => {
  try {
    // const response = await api.get(`/event/tickets/${status}`);
    const response = await api.post(`/event/tickets/${status}`, { userId });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getTicket = async (ticketId) => {
  try {
    const response = await api.post('/event/ticket', { ticketId });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export { getTickets, getTicket };
