import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, Route, RouterProvider, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import RegisterContainer from './containers/RegisterContainer';
import TicketsContainer from './containers/TicketsContainer';
import Events from './components/event/list/Events';
import EventCreateContainer from './containers/EventCreateContainer';
import EventDetailsContainer from './containers/EventDetailsContainer';
import PrivacyPolicy from './components/legal/PrivacyPolicy';
import TermsOfService from './components/legal/TermsOfService';
import TicketDetailsContainer from './containers/TicketDetailsContainer';
import LoginContainer from './containers/LoginContainer';
import ForgotPasswordContainer from './containers/ForgotPasswordContainer';
import ResetPasswordContainer from './containers/ResetPasswordContainer';
import ProfileContainer from './containers/ProfileContainer';
import EventEditContainer from './containers/EventEditContainer';
import MyEvents from './components/event/promoter/my-events/MyEvents';

const router = createBrowserRouter([
  {
    path: '',
    element: <App/>,
    children: [
      {
        path: '',
        element: <LoginContainer/>,
      },
      {
        path: 'forgot-password',
        element: <ForgotPasswordContainer/>,
      },
      {
        path: 'reset-password',
        element: <ResetPasswordContainer/>,
      },
      {
        path: 'register',
        element: <RegisterContainer/>
      }
    ]
  },
  {
    path: '/promoter',
    element: <App/>,
    children: [
      {
        path: '',
        element: <Events/>,
      },
      {
        path: 'event/:eventId',
        element: <EventDetailsContainer/>,
      },
      {
        path: 'event/edit/:eventId',
        element: <EventEditContainer/>,
      },
      {
        path: 'register',
        element: <RegisterContainer/>,
      },
      {
        path: 'login',
        element: <LoginContainer/>
      },
      {
        path: 'event/tickets',
        element: <TicketsContainer/>,
      },
      {
        path: 'event/tickets/:ticketId',
        element: <TicketDetailsContainer/>,
      },
      {
        path: 'my-event',
        element: <MyEvents/>,
      },
      {
        path: 'my-event/create',
        element: <EventCreateContainer/>
      },
      {
        path: 'my-event/:eventId',
        element: <EventDetailsContainer/>,
      },
      {
        path: 'profile',
        element: <ProfileContainer/>
      },
    ],
  },
  {
    path: '/event-goer',
    element: <App/>,
    children: [
      {
        path: '',
        element: <Events/>,
      },
      {
        path: 'event/:eventId',
        element: <EventDetailsContainer/>,
      },
      {
        path: 'event/:eventId/influencer/:influencerId',
        element: <EventDetailsContainer/>,
      },
      {
        path: 'event/:eventId/influencer/:influencerId/ticket/register',
        element: <RegisterContainer/>,
      },
      {
        path: 'login',
        element: <LoginContainer/>
      },
      {
        path: 'event/tickets',
        element: <TicketsContainer/>,
      },
      {
        path: 'event/tickets/:ticketId',
        element: <TicketDetailsContainer/>,
      },
      {
        path: 'profile',
        element: <ProfileContainer/>
      },
    ],
  },
  {
    path: '/privacy-policy', 
    element: <App />,
    children: [
      {
        path: '',
        element: <PrivacyPolicy />
      }
    ]
  },
  {
    path: '/terms-of-service', 
    element: <App />,
    children: [
      {
        path: '',
        element: <TermsOfService />
      }
    ]
  }
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router}>
      <Routes>
        <Route path="/" element={<App/>}/>
      </Routes>
    </RouterProvider>
  </React.StrictMode>
);

reportWebVitals();
