import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import EventDetails from '../components/event/details/EventDetails';
import { getEventDetails, getEventDetailsBySlug, rsvpForEvent, setReminder } from '../services/eventService';

function EventDetailsContainer() {
  const { eventId } = useParams();
  const location = useLocation()
  const navigate = useNavigate();
  const [eventDetails, setEventDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const isEventGoerRoute = location.pathname.startsWith('/event-goer');
  const user = location.state?.user || JSON.parse(localStorage.getItem('user'));
  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        if (!isEventGoerRoute) {
          // const eventData = await getEventDetails(eventId);
          const eventData = await getEventDetailsBySlug(eventId);
          setEventDetails(eventData.data);
        } else {
          const eventData = await getEventDetailsBySlug(eventId)
          setEventDetails(eventData.data)

          // const influcnerId = new URLSearchParams(location.search).get('id')

          const pathParts = location.pathname.split('/');
          const influencerIndex = pathParts.indexOf('influencer');
          const influencerId = influencerIndex !== -1 ? pathParts[influencerIndex + 1] : null;

          if (influencerId) {
            localStorage.setItem('invite', JSON.stringify({ influencerId: influencerId, event: eventId, eventName: eventData.data.name }))
          } else {
            localStorage.setItem('invite', JSON.stringify({ influencerId: null, event: eventId, eventName: eventData.data.name }))
          }
        }
        
        setLoading(false);
      } catch (error) {
        console.error('Error fetching event details:', error);
        setLoading(false);
      }
    };

    fetchEventDetails();
    // eslint-disable-next-line
  }, [eventId]);

  const handleShareEvent = () => {
    // Construct the shareable link
    const shareableLink = `${window.location.origin}/event-goer/event/${eventDetails.slug || eventId}/influencer/${user.userId}`;

    // Use the Web Share API to share the link
    if (navigator.share) {
      navigator.share({
        title: 'Check out this event',
        text: `Join ${eventDetails.name} event`,
        url: shareableLink,
      })
        .then(() => console.log('Shared successfully'))
        .catch((error) => console.error('Error sharing:', error));
    } else if (navigator.clipboard) {
      // Copy the shareable link to the clipboard
      navigator.clipboard.writeText(shareableLink)
        .then(() => {
          console.log('Shareable Link:', shareableLink);
          setMessage('Link copied to clipboard');
          setShowToast(true);
        })
        .catch((error) => console.error('Error copying link:', error));
    } else {
      // Fallback for browsers that do not support the Web Share API
      console.log('Shareable Link:', shareableLink);
    }
  };

  const handleRsvpForRegisteredUser = async () => {
    setLoading(true);
    const ticket = await rsvpForEvent();
    const redirectPath = `/event-goer/event/tickets/${ticket.data.rsvpId}`;
    setLoading(false);
    navigate(redirectPath, { state: { user: user.data, ticket: ticket.data.ticket } });
  };


  const setReminderHandler = async () => {
    const data = {
      eventId: eventDetails?.event_id,
    }

    const response = await setReminder(data);

    setMessage(response.message);
    setShowToast(true);
  }

  return (
    <EventDetails eventDetails={eventDetails}
                  loading={loading}
                  user={user}
                  handleRsvpForRegisteredUser={handleRsvpForRegisteredUser}
                  handleShareEvent={handleShareEvent}
                  setReminderHandler={setReminderHandler}
                  setShowToast={setShowToast}
                  showToast={showToast}
                  toastMessage={message} />
  );
}

export default EventDetailsContainer;
