import React from 'react';
import './ResetPassword.css';
import Toasts from '../common/toasts/Toasts';

function ResetPassword({
  onSubmit,
  loading,
  formData,
  handleChange,
  handleInputBlur,
  errors,
  setShowToast,
  showToast,
  toastMessage
}) {
  const isFormValid = !Object.values(errors).some(error => error);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
        <div className="spinner-grow" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <div className="container">
      <div id="reset-password"
           className="row justify-content-center d-flex align-items-center">
        <div className="col-lg-6">
          <header>
            <div className="text-center mt-5 mb-5">
              <img src="/assets/images/logo.svg" alt="GreatNight Logo" />
            </div>
          </header>
          <main>
            <Toasts show={showToast} message={toastMessage} onClose={() => setShowToast(false)} />
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="email"
                  className="form-label">
                  Email
                </label>
                <input type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  onBlur={handleInputBlur}
                  placeholder="Email"
                  required />
                <div className="form-text text-danger">
                  {errors?.email}
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="otp"
                  className="form-label">
                  OTP
                </label>
                <input type="text"
                  className="form-control"
                  id="otp"
                  name="otp"
                  value={formData.otp}
                  onChange={handleChange}
                  onBlur={handleInputBlur}
                  placeholder="One Time Password"
                  required />
                <div className="form-text text-danger">
                  {errors?.otp}
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="password"
                  className="form-label">
                  Password
                </label>
                <input type="password"
                  className="form-control"
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  onBlur={handleInputBlur}
                  placeholder="Password"
                  required />
                <div className="form-text text-danger">
                  {errors?.password}
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="cell"
                  className="form-label">
                  Confirm Password
                </label>
                <input type="password"
                  className="form-control"
                  id="confirmPassword"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleInputBlur}
                  placeholder="Confirm Password"
                  required />
                <div className="form-text text-danger">
                  {errors?.confirmPassword}
                </div>
              </div>
              <button type="submit"
                className="btn"
                id="continue"
                style={{ marginBottom: '15px' }}
                disabled={!isFormValid}>
                Reset Password
              </button>
            </form>
          </main>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
