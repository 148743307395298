import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import './EventDetails.css';
import Map from '../../common/map/Map';
import { deleteEvent, formatEventTime, setReminder } from '../../../services/eventService'
import Toasts from '../../common/toasts/Toasts';
import ShareEmailModal from './ShareEmailModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faCopy, faShareNodes, faTicket } from '@fortawesome/free-solid-svg-icons';
import { faBell } from '@fortawesome/free-regular-svg-icons';

function EventDetails({
  eventDetails,
  loading,
  user,
  handleRsvpForRegisteredUser,
  handleShareEvent,
  setReminderHandler,
  setShowToast,
  showToast,
  toastMessage,
}) {
  const invite = JSON.parse(localStorage.getItem('invite'));
  const registerLink = `ticket/register`;
  const location = useLocation();
  const navigate = useNavigate();
  // Check if the current route starts with "/event-goer"
  const isEventGoerRoute = location.pathname.startsWith('/event-goer');
  const address = `${eventDetails?.street}, ${eventDetails?.city}, ${eventDetails?.state}, ${eventDetails?.country}`;
  const [showModal, setShowModal] = useState(false);

  const deleteEventHandler = async () => {
    const data = {
      eventId: eventDetails?.event_id,
      promoterId: JSON.parse(localStorage.getItem('user'))?.userId
    }
    const response = await deleteEvent(data);
    console.log(response);
    navigate('/promoter/');
  }

  const handleShareEmailEventHandler = async () => {
    setShowModal(true);
  }

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
        <div className="spinner-grow" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div id="event-details-wrapper"
      className="container">
      <div className="row justify-content-center">
        <div className="col-lg-6">
          <header className="container">
            {/* {isEventGoerRoute && (
              <a href="/event-goer/"
                 className="inter-700 text-decoration-none text-black">
                <img src="/assets/images/icons/left-arrow.svg"
                     alt="Nevigate to home"/> Home
              </a>
            )} */}

            <div className="d-flex justify-content-between align-items-center">
              <h2 className="epilogue-400">Event Details</h2>

              <div className="d-flex align-items-center justify-content-end">
                <FontAwesomeIcon icon={faBell} className="visually-hidden" />
              </div>
            </div>
            <FontAwesomeIcon
        icon={faChevronLeft}
        className="mb-3 d-block text-black mt-3"
        style={{ cursor: 'pointer' }}
        onClick={() => navigate(-1)}
      />
          </header>
          <div className="container">
            <Toasts show={showToast} message={toastMessage} onClose={() => setShowToast(false)} />
            <div id="event-img">
              {eventDetails?.flyer && (<img src={eventDetails?.flyer}
                className="img-fluid w-100 rounded-3 mb-3 "
                alt="Event Flyer" />)}

            </div>
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
              <h1 className="epilogue-700 col-8 text-truncate">
                {eventDetails?.name || 'N/A'}
              </h1>

              <button className="btn remind-me-btn inter-400" onClick={setReminderHandler}>Remind Me</button>
            </div>

            <section id="about-event">
              <h2 className="epilogue-700">Description</h2>

              <p className="inter-400 mb-5" dangerouslySetInnerHTML={{ __html: eventDetails?.description }}></p>

              <h2 className="epilogue-700 mb-0">Date</h2>
              <p className="date inter-400">
                {formatEventTime(eventDetails?.start, eventDetails?.end)}
              </p>

              <h2 className="epilogue-700">Notes</h2>
              <p className="inter-400" dangerouslySetInnerHTML={{ __html: eventDetails?.notes }}></p>
            </section>

            <section id="location">
              <h2 className="epilogue-700">Location</h2>

              <p className="inter-400 d-inline-block">
                {eventDetails?.street}<br /> {eventDetails?.city}, {eventDetails?.state} {eventDetails?.zip}
              </p>

              <div id="map" className="mt-1">
                {/*<img src="/assets/images/map.svg"
                         alt=""
                         className="img-fluid w-100"/>*/}

                <Map address={address} />
              </div>
            </section>

            <div className="w-100">
              {isEventGoerRoute && !user && invite?.influencerId && (eventDetails?.is_promoted === 1) && (
                <Link to={registerLink}
                  className="text-decoration-none btn flex-fill w-100"
                  id="book-event">
                  <FontAwesomeIcon icon={faTicket} /> RSVP
                </Link>
              )}
              {isEventGoerRoute && user && (eventDetails?.is_promoted === 1) && (
                <Link onClick={handleRsvpForRegisteredUser}
                  className="text-decoration-none btn flex-fill w-100"
                  id="book-event">
                  <FontAwesomeIcon icon={faTicket} /> RSVP
                </Link>
              )}

            </div>
            <div className='d-flex justify-content-between'>
              {isEventGoerRoute && user?.userId && (
                <Link onClick={handleShareEvent}
                  className="text-decoration-none btn flex-fill"
                  id="share-event">
                  <FontAwesomeIcon icon={faCopy} /> Share Event
                </Link>
              )}
              {/* {isEventGoerRoute && user?.userId && (
                <Link onClick={handleShareEmailEventHandler}
                  className="text-decoration-none btn ms-3 flex-fill"
                  id="share-event-by-email">
                  <FontAwesomeIcon icon={faShareNodes} /> Share Event
                </Link>
              )} */}
            </div>

            {!isEventGoerRoute && (
              <div>
                <div className="text-center row justify-content-between">
                  {/* <div className="col">
                    {user?.userId && (
                      <Link onClick={handleShareEmailEventHandler}
                        className="text-decoration-none btn w-100"
                        id="share-event-by-email">
                        <FontAwesomeIcon icon={faShareNodes} /> Share Event
                      </Link>
                    )}
                  </div> */}
                  <div className="col">
                    {user?.userId && (
                      <Link onClick={handleShareEvent}
                        className="text-decoration-none btn w-100"
                        id="share-event">
                        <FontAwesomeIcon icon={faCopy} /> Share Event
                      </Link>
                    )}
                  </div>
                </div>
                <div className="text-center row justify-content-between">
                  <div className="col">
                    <Link to="/promoter/"
                      id="book-event"
                      className="text-decoration-none col btn w-100">
                      Save
                    </Link>
                  </div>
                  <div className="col">
                    <Link to={`/promoter/event/edit/${eventDetails?.event_id}`}
                      id="book-event"
                      className="text-decoration-none btn col w-100">
                      Edit Event
                    </Link>
                  </div>
                </div>
              </div>
            )}
            {!isEventGoerRoute && (
              <div className="text-center">
                <Link
                  className="inter-600 link-danger link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover ms-2"
                  onClick={deleteEventHandler}>
                  Delete Event
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
      {eventDetails && <ShareEmailModal showModal={showModal} setShowModal={setShowModal} eventDetails={eventDetails} />}
    </div>
  );
}

export default EventDetails;
