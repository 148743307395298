import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import { createEvent, uploadImage } from '../services/eventService';
import EventCreate from '../components/event/create/EventCreate';


function EventCreateContainer() {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    category: '',
    type: '',
    tags: '',
    venueName: '',
    street: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    start: '',
    end: '',
    startDate: '',
    endDate: '',
    notes: ''
  });

  const navigate = useNavigate();

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedName, setSelectedName] = useState('');
  const [imageURL, setImageURL] = useState('')
  const [address, setAddress] = useState(null)
  const [street, setStreet] = useState(null)
  const [city, setCity] = useState(null)
  const [state, setState] = useState(null)
  const [zip, setZip] = useState(null)
  const [disableButton, setDisableButton] = useState(false)
  const [imageLoading, setImageLoading] = useState(false)
  const [errors, setErrors] = useState({
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: ''
  });
  // eslint-disable-next-line no-unused-vars
  const [venueAddress, setVenueAddress] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulating loading delay with setTimeout
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000);

    // Clean up the timeout
    return () => clearTimeout(timeout);
  }, []);

  const handleFileChange = async (file) => {
  
    console.log("Selected file:", file);

    setImageLoading(true);

  
    // Update state to disable the button and set selected file info
    setDisableButton(true);
    setSelectedFile(file);
    setSelectedName(file.name);
  
    // Initialize FormData and append the selected file
    const formData = new FormData();
    formData.append('image', file);
  
    try {
      // Attempt to upload the image and log the URL
      const url = await uploadImage(formData);
      console.log("Uploaded image URL:", url.data);
  
      // Update state with the new image URL
      setImageURL(url.data.imageUrl);
    } catch (error) {
      // Handle errors (e.g., from the uploadImage function) here
      console.error("Error uploading the image:", error);
    } finally {
      // Re-enable the button whether the upload was successful or not
      setDisableButton(false);
      setImageLoading(false);

    }
  };

  useEffect(() => {
    const validateDateTime = () => {
      const { startDate, endDate, start, end } = formData;
      const format = 'YYYY-MM-DD hh:mm A';
      const timezone = 'America/New_York';

      let newErrors = {};

      if (startDate && endDate) {
        if (moment(startDate).isAfter(moment(endDate))) {
          newErrors.startDate = 'Start date cannot be later than end date';
          newErrors.endDate = 'End date cannot be earlier than start date';
        } else {
          newErrors.startDate = '';
          newErrors.endDate = '';

          if (start && end) {
            const startDateTime = moment.tz(`${startDate} ${start}`, format, timezone).utc();
            const endDateTime = moment.tz(`${endDate} ${end}`, format, timezone).utc();

            if (startDateTime.isAfter(endDateTime)) {
              newErrors.startTime = 'Start time cannot be later than end time';
              newErrors.endTime = 'End time cannot be earlier than start time';
            } else {
              newErrors.startTime = '';
              newErrors.endTime = '';
            }
          }
        }
      }

      // Set errors after all validations
      setErrors(newErrors);
    };

    validateDateTime();
  }, [formData]);


  useEffect(() => {
    console.log(errors);
  }, [errors]); // Log errors whenever it changes


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: name === 'type' ? Number(value) : value,
    }));

    console.log(name, value)

    if (name === 'state') {
      setState(value)
    }

    if (name === 'city') {
      setCity(value)
    }

    if (name === 'street') {
      setStreet(value)
    }

    if (name === 'zip') {
      setZip(value)
    }

    if (state !== null && city !== null && street !== null && zip !== null) {
      setAddress(`${street} ${city}, ${state} ${zip}`)
    }
  };

  const handleVenueSelected = (place) => {
    const address = place.formatted_address;
    setVenueAddress(address);

    // Extract address components
    const addressComponents = place.address_components.reduce((acc, component) => {
      if (component.types.includes('street_number')) {
        acc.street_number = component.long_name;
      } else if (component.types.includes('route')) {
        acc.route = component.long_name;
      } else if (component.types.includes('locality')) {
        acc.city = component.long_name;
      } else if (component.types.includes('administrative_area_level_1')) {
        acc.state = component.short_name; // Using short_name for state abbreviation
      } else if (component.types.includes('postal_code')) {
        acc.postal_code = component.long_name;
      } else if (component.types.includes('country')) {
        acc.country = component.short_name;
      }
      return acc;
    }, {});

    formData.venueName = place.name || '';
    formData.street = addressComponents.street_number ? addressComponents.street_number + ' ' + addressComponents.route : '';
    formData.city = addressComponents.city || '';
    formData.state = addressComponents.state || '';
    formData.zip = addressComponents.postal_code || '';
    formData.country = addressComponents.country || '';
  };

  const handleInputBlur = (e) => {
    const { name, value } = e.target;
    let newErrors = { ...errors }; // Copy the existing errors object

    // Perform validation based on the input field
    switch (name) {
      case 'name':
        newErrors.name = value.trim() === '' ? 'Event title is required' : '';
        break;
      case 'category':
        newErrors.category = value === '' ? 'Category is required' : '';
        break;
      case 'type':
        newErrors.type = value === '' ? 'Type is required' : '';
        break;
      case 'tags':
        newErrors.tags = value === '' ? 'Tag is required' : '';
        break;
      case 'street':
        newErrors.street = value.trim() === '' ? 'Street is required' : '';
        break;
      case 'city':
        newErrors.city = value.trim() === '' ? 'City is required' : '';
        break;
      case 'state':
        newErrors.state = value === '' ? 'State is required' : '';
        break;
      case 'zip':
        newErrors.zip = value.trim() === '' ? 'Postal code is required' : '';
        break;
      case 'startDate':
        newErrors.startDate = value.trim() === '' ? 'Start date is required' : '';
        break;
      case 'endDate':
        newErrors.endDate = value.trim() === '' ? 'End date is required' : '';
        break;
      case 'start':
        newErrors.startTime = value.trim() === '' ? 'Start time is required' : '';
        break;
      case 'end':
        newErrors.endTime = value.trim() === '' ? 'End time is required' : '';
        break;
      default:
        break;
    }

    // Update the errors state with the new errors
    setErrors(newErrors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    moment.tz.setDefault('America/New_York');

    try {
      console.log('formData: ', formData);
      const startDateTime = moment.tz(
        `${formData.startDate} ${formData.start}`,
        'YYYY-MM-DD hh:mm A',
        'America/New_York'
      ).utc().format('YYYY-MM-DDTHH:mm:ss[Z]');

      const endDateTime = moment.tz(
        `${formData.endDate} ${formData.end}`,
        'YYYY-MM-DD hh:mm A',
        'America/New_York'
      ).utc().format('YYYY-MM-DDTHH:mm:ss[Z]');

      const eventData = {
        ...formData,
        start: startDateTime,
        end: endDateTime,
        promoter: JSON.parse(localStorage.getItem('user')).userId,
        url: imageURL
      }
      delete eventData.startDate;
      delete eventData.endDate;

      console.log(eventData)

      // Call the API function to create an event
      const resp = await createEvent(eventData);

      setMessage('Event created successfully.');
      setShowToast(true);

      navigate(`/promoter/event/${resp.data.event_id}`);
    } catch (error) {
      console.error('Error creating event:', error);
      // Handle error gracefully (show error message, etc.)
      setMessage('Error creating event.');
      setShowToast(true);
    }
  };

  return (
    <EventCreate
      formData={formData}
      setFormData={setFormData}
      selectedFile={selectedFile}
      selectedName={selectedName}
      handleFileChange={handleFileChange}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      handleVenueSelected={handleVenueSelected}
      address={address}
      disableButton={disableButton}
      imageLoading={imageLoading}
      errors={errors}
      handleInputBlur={handleInputBlur}
      setShowToast={setShowToast}
      showToast={showToast}
      toastMessage={message}
      loading={loading}
    />
  );
}

export default EventCreateContainer;
