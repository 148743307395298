import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import Cookies from 'js-cookie';
import ResetPassword from '../components/reset-password/ResetPassword';
import { resetPassword } from '../services/userService';

function ResetPasswordContainer() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    email: '',
    otp: '',
    password: '',
    confirmPassword: ''
  });
  const [errors, setErrors] = useState({
    email: '',
    otp: '',
    password: '',
    confirmPassword: ''
  });
  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    // Check if the user is already logged in
    // const userToken = Cookies.get('gn_userToken');
    // if (userToken) {
    //   // Redirect user based on role
    //   const storedUser = localStorage.getItem('user');
    //   if (storedUser) {
    //     const userData = JSON.parse(storedUser);
    //     const redirectPath = userData.role === 'promoter' ? '/promoter' : '/event-goer';
    //     navigate(redirectPath);
    //   }
    // }

    // Check if user's email is stored in localStorage and set it in the form data
    const storedEmail = localStorage.getItem('rememberedEmail');
    if (storedEmail) {
      setFormData(prevState => ({ ...prevState, email: storedEmail }));
    }

    // Simulating loading delay with setTimeout
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000);

    // Clean up the timeout
    return () => clearTimeout(timeout);
  }, [navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleInputBlur = (e) => {
    const { name, value } = e.target;
    let newErrors = { ...errors };

    switch (name) {
      case 'email':
        newErrors.email = value === '' ? 'Email is required' : '';
        break;
      case 'otp':
        newErrors.otp = value === '' ? 'One time password is required' : '';
        break;
      case 'password':
        newErrors.password = value === '' ? 'Password is required' : '';
        break;
      case 'confirmPassword':
        newErrors.confirmPassword = value === '' ? 'Confirm password is required' : '';
        break;
      default:
        break;
    }

    // Additional validation for confirm password
    if (name === 'confirmPassword' && value !== formData.password) {
      newErrors.confirmPassword = 'Passwords do not match';
    }

    setErrors(newErrors);
  };

  const handleSubmit = async (formData) => {
    const { email, otp, password } = formData;
    setLoading(true);

    try {
      const result = await resetPassword(email, otp, password);
      if (result) setMessage(result.message);
      setShowToast(true);
      setLoading(false);

      if (result.success) {
        const timeout = setTimeout(() => {
          navigate('/');
        }, 2000);

        setFormData({
          email: '',
          otp: '',
          password: '',
          confirmPassword: ''
        });

        return () => clearTimeout(timeout);
      }
    } catch (error) {
      setMessage(`Error signing in user: ${error}`);
      setShowToast(true);
      setLoading(false);
    }
  };

  return (
    <ResetPassword
      onSubmit={handleSubmit}
      loading={loading}
      formData={formData}
      handleChange={handleChange}
      handleInputBlur={handleInputBlur}
      errors={errors}
      setShowToast={setShowToast}
      showToast={showToast}
      toastMessage={message}
    />
  );
}

export default ResetPasswordContainer;
