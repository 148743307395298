import React from 'react';
import './ForgotPassword.css';
import Toasts from '../common/toasts/Toasts';

function ForgotPassword({
                 onSubmit,
                 loading,
                 formData,
                 handleChange,
                 handleInputBlur,
                 errors,
                 setShowToast,
                 showToast,
                 toastMessage
               }) {
  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{minHeight: '100vh'}}>
        <div className="spinner-grow" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <div className="container">
      <div id="forgot-password" className="row justify-content-center d-flex align-items-center">
        <div className="col-lg-6">
          <header>
            <div className="text-center mb-5">
              <img src="/assets/images/logo.svg" alt="GreatNight Logo"/>
            </div>
          </header>
          <main>
            <Toasts show={showToast} message={toastMessage} onClose={() => setShowToast(false)}/>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="email"
                       className="form-label">
                  Email
                </label>
                <input type="email"
                       className="form-control"
                       id="email"
                       name="email"
                       value={formData.email}
                       onChange={handleChange}
                       onBlur={handleInputBlur}
                       placeholder="Email"
                       required/>
                <div className="form-text text-danger">
                  {errors?.email}
                </div>
              </div>
              <button type="submit"
                      className="btn"
                      id="continue"
                      style={{marginBottom: '15px'}}>
                Send One Time Password
              </button>
            </form>
          </main>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
