import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Events.css';
import { getUpcomingEvents, getUpcomingNonPromotedEvents } from '../../../services/eventService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';

function Events() {
  const location = useLocation();
  const user = location.state?.user || JSON.parse(localStorage.getItem('user'));
  const [events, setEvents] = useState([]);
  const [nonPromotedEvents, setNonPromotedEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tags, setTags] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [selectedTag, setSelectedTag] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);

  const dayOfWeekMapping = {
    'Sunday': 0,
    'Monday': 1,
    'Tuesday': 2,
    'Wednesday': 3,
    'Thursday': 4,
    'Friday': 5,
    'Saturday': 6
  };
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  useEffect(() => {
    const getEvents = async () => {
      if (user && user.userId && user.role) {
        const response = await getUpcomingEvents();
        if (response.success) {
          const combinedEvents = response.data.events;
          setEvents(combinedEvents);
          setFilteredEvents(combinedEvents);
          if (Array.isArray(response.data.tags)) {
            setTags(Array.from(new Set(response.data.tags)));
            setAllTags(Array.from(new Set(response.data.tags)));
          }
          setLoading(false);
        } else {
          setLoading(false);
        }
      } else {
        console.error('Please sign in or sign up');
        setLoading(false);
      }
    };

    const getNonPromotedEvents = async () => {
      if (user && user.userId && user.role) {
        const response = await getUpcomingNonPromotedEvents();
        if (response.success) {
          const combinedEvents = response.data.events;
          setNonPromotedEvents(combinedEvents);
          setFilteredEvents(prevEvents => [...prevEvents, ...combinedEvents]);
          if (Array.isArray(response.data.tags)) {
            setTags(prevTags => Array.from(new Set([...prevTags, ...response.data.tags])));
            setAllTags(prevTags => Array.from(new Set([...prevTags, ...response.data.tags])));
          }
          setLoading(false);
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    getEvents();
    getNonPromotedEvents();
    // eslint-disable-next-line
  }, []);

  const applyFilters = (dayOfWeek, tag) => {
    let filtered = [...events, ...nonPromotedEvents];

    // Filter by day if a day is selected
    if (dayOfWeek) {
      const today = new Date();
      const todayDayOfWeek = today.getDay();
      const targetDayOfWeek = dayOfWeekMapping[dayOfWeek];

      let selectedDate = new Date(today);
      if (targetDayOfWeek >= todayDayOfWeek) {
        selectedDate.setDate(today.getDate() + (targetDayOfWeek - todayDayOfWeek));
      } else {
        selectedDate.setDate(today.getDate() + (7 - todayDayOfWeek + targetDayOfWeek));
      }

      filtered = filtered.filter(event => {
        const eventDate = new Date(event.start);
        eventDate.setHours(0, 0, 0, 0);
        selectedDate.setHours(0, 0, 0, 0);
        return eventDate.getTime() === selectedDate.getTime();
      });
    }

    // Filter by tag if a tag is selected
    if (tag) {
      filtered = filtered.filter(event =>
        event.tags.split(',').map(tag => tag.trim()).includes(tag)
      );
    }

    setFilteredEvents(filtered);

    // Update tags based on the filtered events (only when filtering by day)
    const filteredTags = [...new Set(filtered.flatMap(event => event.tags.split(',').map(tag => tag.trim())))];
    if (filteredTags.length > 0) {
      setTags(allTags.filter(tag => filteredTags.includes(tag)));
    } else {
      setTags(allTags); // Reset to all tags if no tags are found
      setSelectedTag(null); // Reset selected tag
    }

    // If no filters are applied, reset to show all tags
    if (!dayOfWeek && !tag) {
      setTags(allTags);
    }
  };

  const filterEventsForSpecificDay = (dayOfWeek) => {
    const newSelectedDay = selectedDay === dayOfWeek ? null : dayOfWeek;
    setSelectedDay(newSelectedDay);
    applyFilters(newSelectedDay, selectedTag);
  };

  const filterEventsByTag = (tag) => {
    const newSelectedTag = selectedTag === tag ? null : tag;
    setSelectedTag(newSelectedTag);
    applyFilters(selectedDay, newSelectedTag);
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
        <div className="spinner-grow" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div id="events" className="container pt-4">
      <div className="row justify-content-center">
        <div className="col-lg-6">
          <header>
            <div className="row mb-5">
              <div className="d-flex justify-content-between align-items-center">
                <img src="/assets/images/logo.svg" alt="GreatNight Logo" id="logo" />
                <FontAwesomeIcon icon={faBell} className="visually-hidden" />
              </div>
            </div>
          </header>
          <main>
            <div className="container-fluid">
              {/* Day and Tag Filters */}
              <span style={{ fontWeight: 'bold', display: 'block', marginBottom: '10px' }}>Select a day to filter</span>
              <div className="day-filter-container">
                {daysOfWeek.map((day, index) => (
                  <span
                    key={index}
                    onClick={() => filterEventsForSpecificDay(day)}
                    className={selectedDay === day ? 'selected' : ''}
                  >
                    {day}
                  </span>
                ))}
              </div>

              {/* Only show the tag cloud if there are filtered events */}
              {(filteredEvents.length > 0 || (!selectedDay && !selectedTag)) && tags.length > 0 && (
                <>
                  <span style={{ fontWeight: 'bold', display: 'block', marginBottom: '10px' }}>Select a tag to filter</span>
                  <div className="tag-container">
                    {tags.map((tag, index) => (
                      <span
                        key={index}
                        onClick={() => filterEventsByTag(tag)}
                        className={selectedTag === tag ? 'selected' : ''}
                      >
                        #{tag}
                      </span>
                    ))}
                  </div>
                </>
              )}

              {/* Display filtered events or the "No events" message */}
              {filteredEvents.length > 0 ? (
                filteredEvents.map((event, index) => (
                  <div key={`${event.event_id}-${index}`} className="row">
                    <div className="col">
                      <Link to={`event/${event.slug}`} className="text-decoration-none">
                        <div className="card event border-0">
                          {event.flyer && (
                            <img src={event.flyer} className="card-img-top rounded-3" alt="Event" />
                          )}
                          <div className="card-body ps-0 pe-0">
                            <div className="row">
                              <div className="col-auto">
                                <img src="/assets/images/icons/user.png" alt="" className="d-inline-block place-marker" />
                                <div className="d-inline-block ms-2">
                                  <h5 className="card-title epilogue-600 text-truncate" style={{ maxWidth: '300px' }}>
                                    {event.name}
                                  </h5>
                                  <p className="inter-400 mb-0 location-address">
                                    <span className="location-name mb-0">
                                      {event.venue_name ? `${event.venue_name}, ` : ''}
                                    </span> {event.city}
                                  </p>
                                </div>
                              </div>
                              <div className="col text-end">
                                <FontAwesomeIcon icon={faEllipsisVertical} />
                              </div>
                            </div>
                          </div>
                          <span className="promoted-badge position-absolute top-0 start-0 rounded-0 epilogue-400">Promoted</span>
                        </div>
                      </Link>
                    </div>
                  </div>
                ))
              ) : (
                <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '50vh' }}>
                  <p className="text-center">There are no events based on the filters you selected, please select another.</p>
                </div>
              )}

            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

export default Events;
